<template>
    
    <div class="page__content">

        <HeaderBody/>

        <div class="page__row" style="width: 100%; display: flex; justify-content: center; align-items: center;">
                    <div class="page__col" >
                        <div class="details">
                            <div class="details__container">
                                
                                <p style="padding-left: 10px;">From</p>
                                <div class="exchange__top" style="margin-bottom: 20px;" >
                                    <div class="exchange__cell" >
                                        <div class="exchange__company" ref="block1">
                                            <div class="exchange__head oneee" @click="openBlock1">
                                                <div class="exchange__logo">
                                                    <img :src="c1image" alt="" >
                                                </div>
                                                <div class="exchange__details">
                                                    <div class="exchange__currency">{{c1symbol}}</div>
                                                    <div class="exchange__info">{{c1name}}</div>
                                                </div>
                                                <div class="exchange__arrow">
                                                    <img src="@/assets/dashAssets/img/sArrow.png" alt="" style="width: 15px;">
                                                </div>
                                            </div>
                                            <div class="exchange__body" style="max-height: 500px; overflow-y: scroll; ">

                                                <a class="exchange__item" v-for="coin in coinList" :key="coin.symbol" @click="updateBlock1(coin.name, coin.symbol, coin.image, coin.balance, coin.balanceUsd, coin.price, coin.walletId)">
                                                    <div class="exchange__logo">
                                                        <img :src="coin.image" alt="" style=" border-radius: 50%;">
                                                    </div>
                                                    <div class="exchange__details">
                                                        <div class="exchange__currency">{{coin.symbol}}</div>
                                                        <div class="exchange__info">{{coin.name}}</div>
                                                    </div>
                                                </a>
                                                
                                            </div>
                                        </div>
                                    </div>
                                    
                                    <div class="exchange__cell">
                                        <div class="exchange__info">Balance</div>
                                        <div class="exchange__price">${{c1balanceUsd}}</div>
                                    </div>
                                    
                                    
                                </div>

                                <!-- <div style="width: 100%; display:flex; align-items:center; justify-content:center;">
                                    <img src="./dashAssets/img/swArrow.png" alt="" style="width: 30px;">
                                </div> -->

                                <div class="operations__field field js-field">
                                    
                                    <div class="field__wrap">
                                        <input class="field__input js-field-input" type="number" placeholder="$0.00" required v-model="swapAmount" style="text-align: center; font-size: 30px;">
                                    </div>
                                </div>

                                <p style="padding-left: 10px;">To</p>
                                <div class="exchange__top" style="margin-top: 0px; z-index: 3;">
                                    <div class="exchange__cell" >
                                        <div class="exchange__company" ref="block2" >
                                            <div class="exchange__head twooo" style="z-index:1" @click="openBlock2">
                                                <div class="exchange__logo">
                                                    <img :src="c2image" alt="">
                                                </div>
                                                <div class="exchange__details" >
                                                    <div class="exchange__currency">{{c2symbol}}</div>
                                                    <div class="exchange__info">{{c2name}}</div>
                                                </div>
                                                <div class="exchange__arrow">
                                                    <img src="@/assets/dashAssets/img/sArrow.png" alt="" style="width: 15px;">
                                                </div>
                                            </div>
                                            <div class="exchange__body" style="max-height: 500px; overflow-y: scroll; margin-top: -90%; margin-left: 10%; z-index: 99999999999999999;">

                                                <a class="exchange__item" v-for="coin in coinList" :key="coin.symbol" @click="updateBlock2(coin.name, coin.symbol, coin.image, coin.balance, coin.balanceUsd, coin.price, coin.walletId)">
                                                    <div class="exchange__logo">
                                                        <img :src="coin.image" alt="" style=" border-radius: 50%;">
                                                    </div>
                                                    <div class="exchange__details">
                                                        <div class="exchange__currency">{{coin.symbol}}</div>
                                                        <div class="exchange__info">{{coin.name}}</div>
                                                    </div>
                                                </a>
                                                
                                            </div>
                                        </div>
                                    </div>
                                    
                                    <div class="exchange__cell">
                                        <div class="exchange__info">Balance</div>
                                        <div class="exchange__price">${{c2balanceUsd}}</div>
                                    </div>
                                    
                                    
                                </div>
                                
                               
                                <button class="details__btn btn btn_blue btn_wide btn_big" style="z-index: 2" @click="swap">Swap</button>

                             


                            </div>
                        </div>
                    </div>
                    
        </div>

        <div class="activity" style="margin-top: -30px;" id="noT">
                    <div class="activity__container">
                        <div class="activity__stage h6 mobile-show" style="color:#ffffff">Swap history</div>
                        <div class="activity__stage h6 mobile-hide" style="color:#ffffff">Swap history</div>
                        
                        <div style="display: flex; justify-content: center; align-items:center; width: 100%">

                            <br>
                            <h3 style="color: #ffffff">No transactions yet...</h3>
                            <br>

                        </div>
                        
                    </div>
                    
        </div>

        <div class="activity" style="margin-top: -30px;" id="yesT">
                    <div class="activity__container">
                        <div class="activity__stage h6 mobile-show" style="color:#ffffff">Swap history <span style="font-size:12px; font-weight: 100;"><br>Select a transaction to see more details</span></div>

                        <div class="activity__stage h6 mobile-hide" style="color:#ffffff">Swap history <span style="font-size:12px; font-weight: 100;"><br>Select a transaction to see more details</span></div>
                        
                        <div class="activity__table">
                            <div class="activity__row activity__row_head">
                                
                                <div class="activity__cell">DATE & TIME</div>
                                <div class="activity__cell">PAIR</div>
                                <div class="activity__cell">
                                    STATUS
                                </div>
                                <div class="activity__cell">
                                    TRANSACTION ID
                                </div>
                                <div class="activity__cell">
                                    AMOUNT <span class="activity__hidden"></span>
                                    
                                </div>

                                
                            </div>

                            <div class="activity__row" @click="ar(trans.amount, trans.sent, trans.sentSymbol, trans.received, trans.receivedSymbol, trans.date, trans.id )" v-for="trans in transList" :key="trans.amount">
                                
                                
                                <div class="activity__cell">
                                    <div class="activity__date">{{trans.date}}</div>
                                </div>

                                <div class="activity__cell">
                                    <div class="activity__company">
                                        <div class="activity__logo">
                                            <div style="display: flex; justify-content:center; align-items: center;">
                                                <img :src="trans.sentImage" alt="" style=" border-radius: 50%;">
                                                <img :src="trans.receivedImage" alt="" style=" border-radius: 50%;">
                                            </div>
                                        </div>
                                        
                                    </div>

                                    <div class="activity__transaction" style="margin-left: -15px;">{{trans.sentSymbol}} to {{trans.receivedSymbol}}</div>

                                </div>

                                <div class="activity__cell">
                                    <div class=" activity__status">successful</div>
                                    
                                </div>

                                <div class="activity__cell">
                                    <div class=" activity__address">{{trans.id}}</div>
                                    
                                </div>

                                <div class="activity__cell">
                                    <div class=" activity__address">{{trans.amount}}</div>
                                    <div class=" activity__transaction"></div>
                                </div>

                                
                                
                            </div>

                            
                        </div>
                        
                    </div>
                    <div class="activity__sidebar">
                        <div class="activity__wrap">
                            <button class="activity__close" @click="ac">
                                <img src="@/assets/dashAssets/img/close2.png" alt="">
                            </button>
                            <div class="activity__head">
                                
                                <div class="activity__details">
                                    <div class="activity__info">{{tSentSymbol}} to {{tReceivedSymbol}}</div>
                                    <div class="activity__date">{{tDate}}</div>
                                </div>
                            </div>

                            <div class="activity__line">
                                
                                <div class="activity__money">${{tAmount}}</div>
                                
                            </div>
                            
                            <div class="activity__code"></div>
                            <div class="activity__parameters">

                                <div class="activity__parameter">
                                    
                                    <div class="activity__preview">
                                        <img src="@/assets/dashAssets/img/coins-1.svg" alt="">
                                    </div>

                                    <div class="activity__box">
                                        
                                        <div class="activity__category">Amount sent</div>
                                        <div class="activity__value">{{tSentAmount}} {{tSentSymbol}}</div>
                                    </div>
                                </div>

                                <div class="activity__parameter">
                                    
                                    <div class="activity__preview">
                                        <img src="@/assets/dashAssets/img/coins-1.svg" alt="">
                                    </div>

                                    <div class="activity__box">
                                        
                                        <div class="activity__category">Amount received</div>
                                        <div class="activity__value">{{tReceivedAmount}} {{tReceivedSymbol}}</div>
                                    </div>
                                </div>

                                <div class="activity__parameter">
                                    <div class="activity__preview">
                                        <img src="@/assets/dashAssets/img/check.svg" alt="">
                                    </div>
                                    <div class="activity__box">
                                        <div class="activity__category">Status</div>
                                        <div class="activity__status">Successful</div>
                                    </div>
                                </div>

                                <div class="activity__parameter">
                                <div class="activity__preview"><img src="@/assets/dashAssets/img/cheque.svg" alt=""></div>
                                <div class="activity__box">
                                    <div class="activity__category">Transaction ID</div>
                                    <div class="activity__value">{{tId}}</div>
                                </div>
                                </div>
                                
                                
                                
                            </div>
                        </div>
                    </div>
        </div>

    </div>

    <div style=" display: flex; justify-content: center; align-items: center;  " class="loadingModalBg" ref="loadingModal">

<div class="popup__container" style="width: 300px; background-color:#1F2128; border-radius: 30px; padding: 20px;">
            
    <div class="popup__box">

        <div class="popup__fieldset" >
            
            <div class="popup__row" style="width: 100%; display: flex; flex-direction: column; justify-content: center; align-items: center;">
                
                <img src="@/assets/dashAssets/img/loading.gif" alt="">

                <p style="text-align: center; color: #ffffff;">{{loadingText}}</p>
                
            </div>

            
            
            
        </div>
        

        

        
    </div>
    
</div>

    </div>

    <div style=" display: flex; justify-content: center; align-items: center;  " class="loadingModalBg" ref="successModal">

        <div class="popup__container" style="width: 300px; background-color:#1F2128; border-radius: 30px; padding: 20px;">
                    
            <div class="popup__box">

                <div class="popup__fieldset" >
                    
                    <div class="popup__row" style="width: 100%; display: flex; flex-direction: column; justify-content: center; align-items: center;">
                        
                        <img src="@/assets/dashAssets/img/done.png" alt="" style="width: 100px; margin: 10px;">

                        <p style="text-align: center; margin-bottom: 40px; color: #ffffff;">{{successText}}</p>

                        <button class="operations__btn btn btn_blue" @click="closeS">Okay</button>
                        
                    </div>

                    
                    
                    
                    
                </div>
                

                

                
            </div>
            
        </div>

    </div>

    <div style=" display: flex; justify-content: center; align-items: center;  " class="loadingModalBg" ref="errorModal">

        <div class="popup__container" style="width: 300px; background-color:#1F2128; border-radius: 30px; padding: 20px;">
                    
            <div class="popup__box">

                <div class="popup__fieldset" >
                    
                    <div class="popup__row" style="width: 100%; display: flex; flex-direction: column; justify-content: center; align-items: center;">
                        
                        <img src="@/assets/dashAssets/img/error.png" alt="" style="width: 100px; margin: 10px;">

                        <p style="text-align: center; margin-bottom: 40px; color: #ffffff;">{{errorText}}</p>

                        <button class="operations__btn btn btn_blue" @click="closeE">Okay</button>
                        
                    </div>

                    
                    
                    
                </div>
                

                

                
            </div>
            
        </div>

    </div>

</template>

<style scoped>

    ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: grey;
    opacity: 1; /* Firefox */
    }

    :-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: grey;
    }

    ::-ms-input-placeholder { /* Microsoft Edge */
    color: grey;
    }
</style>

<script>

    import { doc, addDoc, collection, query, where, getDocs, serverTimestamp, orderBy, updateDoc } from "firebase/firestore"; 
    import { db } from '@/firebase';
    import axios from 'axios';
    import HeaderBody from '@/components/dashboard/HeaderBody.vue';

    export default {

        components:{
            HeaderBody,
        },

        mounted(){
            this.getCoinSymbols();
            this.magnific();
            this.niceSelect();

            //toggle active
            document.getElementById("rWallet").classList.remove("active");
            document.getElementById("rSwap").classList.add("active");
            document.getElementById("rStaking").classList.remove("active");
            document.getElementById("rTrans").classList.remove("active");
            document.getElementById("rCard").classList.remove("active");
        },

        data(){
            return{
                loadingText: "",
                errorText: "",
                successText: "",
                swapAmount: "",
                sl:[],
                marketPrice:[],
                marketPriceDisplay:[],
                marketCap:[],
                marketChange:[],
                marketImage:[],
                coinList:[], 
                c1balanceUsd: "",
                c1balance:"",
                c1price: "",
                c1image: "",
                c1name: "",
                c1symbol: "",
                c1walletId: "",

                c2balanceUsd: "",
                c2balance:"",
                c2price: "",
                c2image: "",
                c2name: "",
                c2symbol: "",
                c2walletId: "",

                tDate: "",
                tId: "",
                tAmount: "",
                tSentAmount: "",
                tReceivedAmount: "",
                tSentSymbol: "",
                tReceivedSymbol: "",
                transList:[],
            }
        },

        methods:{

            niceSelect(){
                $('.select').niceSelect();
            },

            magnific(){
                var link = $('.js-popup-open');
                link.magnificPopup({
                    type: 'inline',
                    fixedContentPos: true,
                    removalDelay: 200,
                    callbacks: {
                        beforeOpen: function beforeOpen() {
                            this.st.mainClass = this.st.el.attr('data-effect');
                        }
                    }
                });
            },

            closeS(){
                this.$refs.successModal.classList.remove("bg-active");
                this.$router.go();
            },

            closeE(){
                this.$refs.errorModal.classList.remove("bg-active");
            },

            openBlock1(){
                //this.$refs.block1.classList.toggle("active"); 
                $("oneee").parents('.exchange__company').toggleClass('active');
                console.log("BLOCK1 OPEN")
            },

            closeBlock1(){
                this.$refs.block1.classList.remove("active"); 
                //$(this).parents('.exchange__company').remove('active');
            },

            openBlock2(){
                //this.$refs.block2.classList.toggle("active"); 
                $("twooo").parents('.exchange__company').toggleClass('active');
            },

            closeBlock2(){
                this.$refs.block2.classList.remove("active"); 
                //$(this).parents('.exchange__company').toggleClass('active');
            },

            round(num, decimals){
                return +(Math.round(num + "e" + decimals) + "e-" + decimals);
            },

            updateBlock1(name, symbol, image, balance, balanceUsd, price, walletId){
                this.c1name = name;
                this.c1symbol = symbol;
                this.c1image = image;
                this.c1balance = balance;
                this.c1balanceUsd = this.round(Number(balanceUsd) , 2);
                this.c1price = price;
                this.c1walletId = walletId;

                this.$refs.block1.classList.remove("active"); 
                
            },

            updateBlock2(name, symbol, image, balance, balanceUsd, price, walletId){
                this.c2name = name;
                this.c2symbol = symbol;
                this.c2image = image;
                this.c2balance = balance;
                this.c2balanceUsd = this.round(Number(balanceUsd) , 2);
                this.c2price = price;
                this.c2walletId = walletId;
                this.$refs.block2.classList.remove("active"); 
                
            },

            async swap(){
                this.loadingText= "Processing transaction...";
                this.$refs.loadingModal.classList.add("bg-active");

                if(this.swapAmount == ""){
                    this.$refs.loadingModal.classList.remove("bg-active");
                    this.$refs.errorModal.classList.add("bg-active");
                    this.errorText = "Invalid amount";
                    return;
                }

                if(Number(this.swapAmount) < 10){
                    this.$refs.loadingModal.classList.remove("bg-active");
                    this.$refs.errorModal.classList.add("bg-active");
                    this.errorText = "The minimum amount is $10";
                    return;
                }

                if(this.c1symbol == this.c2symbol){
                    this.$refs.loadingModal.classList.remove("bg-active");
                    this.$refs.errorModal.classList.add("bg-active");
                    this.errorText = "Can not swap "+this.c1symbol+" for "+this.c2symbol;
                    return;
                }

                if(Number(this.c1balanceUsd) < Number(this.swapAmount)){
                    this.$refs.loadingModal.classList.remove("bg-active");
                    this.$refs.errorModal.classList.add("bg-active");
                    this.errorText = "Insufficient "+this.c1symbol+" balance";
                    return;
                }

                //swap

                //update coin1
                let c1CoinAmount = Number(this.swapAmount) / Number(this.c1price)
                let newC1Balance = Number(this.c1balance) - Number(c1CoinAmount);

                const c1Ref = doc(db, "UserWallets", String(this.c1walletId));
                await updateDoc(c1Ref, {
                    balance: newC1Balance,
                });

                //update coin2
                let c2CoinAmount = Number(this.swapAmount) / Number(this.c2price)
                let newC2Balance = Number(this.c2balance) + Number(c2CoinAmount);

                const c2Ref = doc(db, "UserWallets", String(this.c2walletId));
                await updateDoc(c2Ref, {
                    balance: newC2Balance,
                });

                //upload transaction
                await addDoc(collection(db, "SwapTrans"), {
                      amount: this.swapAmount,
                      status: "successful",
                      sent: c1CoinAmount,
                      sentSymbol: this.c1symbol,
                      received: c2CoinAmount,
                      receivedSymbol: this.c2symbol,
                      timeStamp: serverTimestamp(),
                      userId: sessionStorage.getItem("id"),
                      name: sessionStorage.getItem("name"),
                      email: sessionStorage.getItem("email"),
                      sentImage: this.c1image,
                      receivedImage: this.c2image,
                });

                //successful

                this.$refs.loadingModal.classList.remove("bg-active");
                this.$refs.successModal.classList.add("bg-active");
                this.successText = "Successfully swapped from "+this.c1symbol+" to "+this.c2symbol;

            },

            async getSwapTrans(){
                let i = -1;
                const q = query(collection(db, "SwapTrans"), where("userId", "==", sessionStorage.getItem("id")), orderBy("timeStamp", "desc")); 
                const querySnapshot = await getDocs(q);
                
                if(querySnapshot.docs.length > 0){
                    let i = -1;
                    let a2;
                    document.getElementById("noT").style.display = "none";

                    querySnapshot.forEach((doc) => {
                        i++;

                        a2 = String(new Date(Number(doc.data().timeStamp.seconds) * 1000));

                        this.transList[i] = {
                            id: doc.id,
                            amount: Number(doc.data().amount).toLocaleString("us-US"),
                            received: Number(doc.data().received),
                            receivedSymbol: doc.data().receivedSymbol,
                            receivedImage: doc.data().receivedImage,
                            sent: Number(doc.data().sent),
                            sentSymbol: doc.data().sentSymbol,
                            sentImage: doc.data().sentImage,
                            date : a2.slice(0, -36)
                        }

                        
                    })



                }
                else{
                    document.getElementById("yesT").style.display = "none";
                    document.getElementById("noT").style.display = "flex";
                    
                }

                this.$refs.loadingModal.classList.remove("bg-active");
                
            },

            ar(amount, sentAmount, sentSymbol, receivedAmount, receivedSymbol, date, id){
                $('.activity__table').addClass('small');
	            $('.activity__sidebar').addClass('visible');

                this.tAmount = amount;
                this.tSentAmount = sentAmount;
                this.tSentSymbol = sentSymbol;
                this.tReceivedAmount = receivedAmount;
                this.tReceivedSymbol = receivedSymbol;
                this.tDate = date;
                this.tId = id;
            },

            ac(){
                $('.activity__table').removeClass('small');
	            $('.activity__sidebar').removeClass('visible');
            },

            async getCoinSymbols(){

                document.getElementById("noT").style.display = "none";
                //start loading
                this.loadingText= "Loading...";
                this.$refs.loadingModal.classList.add("bg-active"); 

                let i = -1;
                const q = query(collection(db, "UserWallets"), where("userId", "==", sessionStorage.getItem("id")), orderBy("balance", "desc")); 
                const querySnapshot = await getDocs(q); 

                querySnapshot.forEach((doc) => {
                    i++;
                    this.sl[i] = doc.data().symbol;
                    if(this.sl[i] == "OMC"){
                        this.sl[i] = "OSMO"
                    }
                    if(this.sl[i] == "IOTA"){
                        this.sl[i] = "MIOTA"
                    }
                    if(this.sl[i] == "ALBT"){
                        this.sl[i] = "GMT"
                    }
                })

                this.getMarketData();
            },

            getMarketData(){
            
            //get coin prices and changes
            axios.get('https://min-api.cryptocompare.com/data/pricemultifull?fsyms='+this.sl[0]+','+this.sl[1]+','+this.sl[2]+','+this.sl[3]+','+this.sl[4]+','+this.sl[5]+','+this.sl[6]+','+this.sl[7]+','+this.sl[8]+','+this.sl[9]+','+this.sl[10]+','+this.sl[11]+','+this.sl[12]+','+this.sl[13]+','+this.sl[14]+','+this.sl[15]+','+this.sl[16]+','+this.sl[17]+','+this.sl[18]+','+this.sl[19]+','+this.sl[20]+','+this.sl[21]+','+this.sl[22]+','+this.sl[23]+','+this.sl[24]+','+this.sl[25]+','+this.sl[26]+','+this.sl[27]+','+this.sl[28]+','+this.sl[29]+','+this.sl[30]+','+this.sl[31]+','+this.sl[32]+','+this.sl[33]+','+this.sl[34]+','+this.sl[35]+','+this.sl[36]+','+this.sl[37]+','+this.sl[38]+','+this.sl[39] +','+this.sl[40]+','+this.sl[41]+','+this.sl[42]+','+this.sl[43]+','+this.sl[44]+','+this.sl[45]+','+this.sl[46]+','+this.sl[47]+','+this.sl[48]+','+this.sl[49]+','+this.sl[50]+','+this.sl[51]+','+this.sl[52]+','+this.sl[53]+','+this.sl[54]+','+this.sl[55]+','+this.sl[56] +'&tsyms=USD')
            .then( response => {

                for(let i = 0; i < this.sl.length; i++){
                    
                    this.marketPrice[i] = Number(Object.values(response.data.RAW)[i].USD.PRICE)
                    this.marketPriceDisplay[i] = Object.values(response.data.DISPLAY)[i].USD.PRICE;
                    this.marketCap[i] = Object.values(response.data.DISPLAY)[i].USD.MKTCAP;
                    this.marketChange[i] = Number(Object.values(response.data.RAW)[i].USD.CHANGEPCTDAY).toFixed(2);
                    // this.marketImage[i] = String("https://www.cryptocompare.com"+Object.values(response.data.RAW)[i].USD.IMAGEURL);
                    this.marketImage[i] = String("https://assets.coincap.io/assets/icons/"+String(this.sl[i]).toLowerCase()+"@2x.png");
                    
                    
                }

                this.getCoins();
            }); 


            

            // return {
            //     price: Number(Object.values(response.data.RAW)[0].USD.PRICE),
            //     priceDisplay: Number(Object.values(response.data.DISPLAY)[0].USD.PRICE),
            //     marketCapDisplay: Object.values(response.data.DISPLAY)[0].USD.MKTCAP,
            //     change: Number(Object.values(response.data.RAW)[0].USD.CHANGEPCTDAY),
            //     image:  String("https://www.cryptocompare.com"+Object.values(response.data.RAW)[0].USD.IMAGEURL),
            // }
            },

            async minusBalFix(walletId){
                const walletRef = doc(db, "UserWallets", String(walletId));
                await updateDoc(walletRef, {
                    balance: 0
                });
            },

            async getCoins(){

                let i = -1;
                let tempA = 0;
                let tempB = 0;
                let cImage;
                let balance;

                const q = query(collection(db, "UserWallets"), where("userId", "==", sessionStorage.getItem("id")), orderBy("balance", "desc"));

                const querySnapshot = await getDocs(q);
                querySnapshot.forEach((doc) => {
                    
                    i++;

                    //check balance not minus
                    balance = doc.data().balance;

                    if(balance < 0 || isNaN(balance)){
                        balance = 0;
                        this.minusBalFix(doc.id);
                    }

                    tempA = Number(balance);
                    tempB = Number(balance) * Number(this.marketPrice[i]);

                    if(doc.data().symbol == "OMC"){
                        cImage = "https://firebasestorage.googleapis.com/v0/b/omcn-62d76.appspot.com/o/omicron.png?alt=media&token=26b01855-fb0d-4811-a1af-4f971bc37067";
                    }
                    else if(doc.data().name == "XDC Network"){
                        cImage = "https://assets.coingecko.com/coins/images/2912/large/xdc-icon.png?1633700890"
                    }
                    else if(doc.data().name == "Algorand"){
                        cImage = "https://firebasestorage.googleapis.com/v0/b/kryp-26f92.appspot.com/o/images%2Falgo.png?alt=media&token=b41200d3-a6c7-4818-af40-90abdfd579d4"
                    }
                    else if(doc.data().name == "Polkadot"){
                        cImage = "https://firebasestorage.googleapis.com/v0/b/kryp-26f92.appspot.com/o/images%2Fdot.png?alt=media&token=f55f3c4e-15e4-4d1a-bdb5-5139bc6a4dda"
                    }
                    else if(doc.data().name == "Terra classic"){
                        cImage = "https://firebasestorage.googleapis.com/v0/b/kryp-26f92.appspot.com/o/images%2Fluna.png?alt=media&token=19c7fc87-ad9e-4abe-9d68-119dc5d0c749"
                    }
                    else if(doc.data().name == "Terra luna"){
                        cImage = "https://firebasestorage.googleapis.com/v0/b/omcn-62d76.appspot.com/o/images%2Fterra.png?alt=media&token=1ece4cc8-13fc-4adf-8ee8-8fa1d0742986"
                    }
                    else if(doc.data().symbol == "FTT"){
                        cImage = "https://firebasestorage.googleapis.com/v0/b/omcn-62d76.appspot.com/o/images%2Fftx.png?alt=media&token=6a8abda1-bb7b-4f1b-87c0-a2d3ee692809"
                    }
                    else if(doc.data().symbol == "GALA"){
                        cImage = "https://firebasestorage.googleapis.com/v0/b/omcn-62d76.appspot.com/o/images%2Fgala.png?alt=media&token=22129083-981c-4707-81b3-d81e0893edee"
                    }
                    else if(doc.data().symbol == "IOTA"){
                        cImage = "https://firebasestorage.googleapis.com/v0/b/omcn-62d76.appspot.com/o/images%2Fiota.png?alt=media&token=607b1799-b383-4be1-a1ba-0d7cf5e8c779"
                    }
                    else if(doc.data().symbol == "CSPR"){
                        cImage = "https://firebasestorage.googleapis.com/v0/b/omcn-62d76.appspot.com/o/images%2FcsprImg.png?alt=media&token=d2efcdae-fb30-4472-a81c-502632b31f65"
                    }
                    else if(doc.data().symbol == "ALBT"){
                        cImage = "https://assets.coincap.io/assets/icons/albt@2x.png"
                    }
                    else if(doc.data().symbol == "LADYS"){
                        cImage = "https://www.cryptocompare.com/media/44131898/ladys.png"
                    }
                    else if(doc.data().symbol == "JTO"){
                        cImage = "https://s2.coinmarketcap.com/static/img/coins/200x200/28541.png"
                    }
                    else if(doc.data().symbol == "PYTH"){
                        cImage = "https://www.cryptocompare.com/media/44154245/pyth.png"
                    }
                    else if(doc.data().symbol == "QI"){
                        cImage = "https://www.cryptocompare.com/media/43717694/qi.png";
                    }
                    else if(doc.data().symbol == "PEPE"){
                        cImage = "https://www.cryptocompare.com/media/44082118/pepe.png";
                    }
                    else if(doc.data().symbol == "BOME"){
                        cImage = "https://https://www.cryptocompare.com/media/44224537/bome.png";
                    }
                    else if(doc.data().symbol == "INJ"){
                        cImage = "https://www.cryptocompare.com/media/43687858/inj.png";
                    }
                    else if(doc.data().symbol == "GRT"){
                        cImage = "https://www.cryptocompare.com/media/37747239/grt.png";
                    }
                    else if(doc.data().symbol == "SUI"){
                        cImage = "https://www.cryptocompare.com/media/44082045/sui.png";
                    }
                    else if(doc.data().symbol == "TIA"){
                        cImage = "https://www.cryptocompare.com/media/44154182/tia.png";
                    }
                    else if(doc.data().symbol == "FLR"){
                        cImage = "https://www.cryptocompare.com/media/43977099/flr.png";
                    }
                    else if(doc.data().symbol == "AXL"){
                        cImage = "https://www.cryptocompare.com/media/43717605/axl.png";
                    }
                    else if(doc.data().symbol == "SERSH"){
                        cImage = "https://www.cryptocompare.com/media/44221940/sersh.png";
                    }
                    else if(doc.data().symbol == "ROOT"){
                        cImage = "https://www.cryptocompare.com/media/44222509/root.png";
                    }
                    else{
                        cImage = this.marketImage[i];
                    }

                    this.coinList[i] = {

                        walletId: doc.id,
                        name: doc.data().name,
                        symbol: doc.data().symbol,
                        color: doc.data().color,
                        address: doc.data().address,
                        balance: this.round(tempA, 7),
                        balanceUsd:  this.round(tempB, 2),
                        image: cImage,
                        price: this.marketPrice[i],
                        priceDisplay: this.marketPriceDisplay[i],
                        change: Number(this.marketChange[i]).toFixed(2),
                        marketCap: this.marketCap[i],
                    }
                    

                });

                this.c1balance = this.coinList[0].balance;
                this.c1price = this.coinList[0].price;
                this.c1balanceUsd = this.round(Number(this.coinList[0].balanceUsd), 2);
                this.c1name = this.coinList[0].name;
                this.c1symbol = this.coinList[0].symbol;
                this.c1image = this.coinList[0].image;
                this.c1walletId = this.coinList[0].walletId;

                this.c2balance = this.coinList[1].balance;
                this.c2price = this.coinList[1].price;
                this.c2balanceUsd = this.round(Number(this.coinList[1].balanceUsd), 2);
                this.c2name = this.coinList[1].name;
                this.c2symbol = this.coinList[1].symbol;
                this.c2image = this.coinList[1].image;
                this.c2walletId = this.coinList[1].walletId;

                
                this.getSwapTrans();


            },
        }


    }
</script>