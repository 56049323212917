<template>
   
   <div class="login" style="background-color:#1F2128 ;">
      <div class="login__row">
        <div class="login__col">
          <h1 class="login__title h1 text-white">One with <br>the future</h1>
          <div class="login__info h6 text-white">OMCDEX</div>
          <div class="login__preview"><img src="@/assets/dashAssets/img/figures-5.png" alt=""></div>
        </div>
        <div class="login__col">
          <form class="login__form" @submit.prevent="verify">
            <div class="login__stage h4 text-white"> Check your email <br> <p style="font-size: 13px; margin-top: 5px;">Check your email for a 4 digit pin</p></div>

            <div class="login__field field js-field">
              <div class="field__label"></div>
              <div class="field__wrap"><input class="field__input js-field-input" style="color:grey" type="number" placeholder="* * * *" required v-model="pin"></div>
            </div>
            
            
              <br>
            
            <button class="login__btn btn btn_blue btn_wide text-white" >Submit</button>


            <div class="login__flex">
                <div class="login__text text-white">Didn't receive the pin?</div>
                
                <p class="login__link" @click="resend">
                  Resend
                </p>
  
              </div>
            
            </form>
        </div>
      </div>
   </div>

<div style=" display: flex; justify-content: center; align-items: center;  " class="loadingModalBg" ref="loadingModal">

<div class="popup__container" style="width: 400px; background-color:#1F2128; border-radius: 30px; padding: 20px;">
            
    <div class="popup__box">

        <div class="popup__fieldset" >
            
            <div class="popup__row" style="width: 100%; display: flex; flex-direction: column; justify-content: center; align-items: center;">
                
                <img src="@/assets/dashAssets/img/loading.gif" alt="">

                <p style="text-align: center; color: #ffffff;">{{loadingText}}</p>
                
            </div>

            
            
            
        </div>
        

        

        
    </div>
    
</div>

</div>

<div style=" display: flex; justify-content: center; align-items: center;  " class="loadingModalBg" ref="successModal">

<div class="popup__container" style="width: 400px; background-color:#1F2128; border-radius: 30px; padding: 20px;">
            
    <div class="popup__box">

        <div class="popup__fieldset" >
            
            <div class="popup__row" style="width: 100%; display: flex; flex-direction: column; justify-content: center; align-items: center;">
                
                <img src="@/assets/dashAssets/img/done.png" alt="" style="width: 100px; margin: 10px;">

                <p style="text-align: center; margin-bottom: 40px; color: #ffffff;">{{successText}}</p>

                <button class="operations__btn btn btn_blue" @click="closeS">Okay</button>
                
            </div>

            
            
            
            
        </div>
        

        

        
    </div>
    
</div>

</div>

<div style=" display: flex; justify-content: center; align-items: center;  " class="loadingModalBg" ref="errorModal">

<div class="popup__container" style="width: 400px; background-color:#1F2128; border-radius: 30px; padding: 20px;">
            
    <div class="popup__box">

        <div class="popup__fieldset" >
            
            <div class="popup__row" style="width: 100%; display: flex; flex-direction: column; justify-content: center; align-items: center;">
                
                <img src="@/assets/dashAssets/img/error.png" alt="" style="width: 100px; margin: 10px;">

                <p style="text-align: center; margin-bottom: 40px; color: #ffffff;">{{errorText}}</p>

                <button class="operations__btn btn btn_blue" @click="closeE">Okay</button>
                
            </div>

            
            
            
        </div>
        

        

        
    </div>
    
</div>

</div>

</template>

<style scoped>

    @import url(@/assets/dashAssets/css/app.css);

    ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: white;
    opacity: 1; /* Firefox */
    }

    :-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: white;
    }

    ::-ms-input-placeholder { /* Microsoft Edge */
    color: white;
    }

</style>

<script>
    import { doc, setDoc, collection, query, where, getDocs, serverTimestamp } from "firebase/firestore"; 
    import { db } from '@/firebase';
    import { secureToken } from '@/smtp';

    export default {
        
        mounted(){
          
          //console.log(this.test("BTC").price)

            //SVG FOR EVEYBODY
            const script2Check = document.getElementById("Script2");
            if(script2Check){
                document.body.removeChild(script2Check);
            }
            let Script2 = document.createElement("script");
            Script2.setAttribute("src", "https://firebasestorage.googleapis.com/v0/b/omcn-62d76.appspot.com/o/DASH%20JS%2Fsvg4everybody.min.js?alt=media&token=ce8a5c47-4a98-4ae7-a4db-c8f07f09cc8f");
            Script2.setAttribute("id", "Script2");
            document.body.appendChild(Script2);

            //OWL CAROUSEL
            const script3Check = document.getElementById("Script3");
            if(script3Check){
                document.body.removeChild(script3Check);
            }
            let Script3 = document.createElement("script");
            Script3.setAttribute("src", "https://firebasestorage.googleapis.com/v0/b/omcn-62d76.appspot.com/o/DASH%20JS%2Fowl.carousel.min.js?alt=media&token=97c8ad60-ade0-464b-ba41-b160a85d79b1");
            Script3.setAttribute("id", "Script3");
            document.body.appendChild(Script3);

            //NICE SELECT
            const script4Check = document.getElementById("Script4");
            if(script4Check){
                document.body.removeChild(script4Check);
            }
            let Script4 = document.createElement("script");
            Script4.setAttribute("src", "https://firebasestorage.googleapis.com/v0/b/omcn-62d76.appspot.com/o/DASH%20JS%2Fjquery.nice-select.min.js?alt=media&token=69c0a5bc-91e5-4087-8805-e909be8fdc9e");
            Script4.setAttribute("id", "Script4");
            document.body.appendChild(Script4);

            //APEX CHART
            const script5Check = document.getElementById("Script5");
            if(script5Check){
                document.body.removeChild(script5Check);
            }
            let Script5 = document.createElement("script");
            Script5.setAttribute("src", "https://firebasestorage.googleapis.com/v0/b/omcn-62d76.appspot.com/o/DASH%20JS%2Fapexcharts.min.js?alt=media&token=bafc7498-0b92-4648-a776-d8860b5168e5");
            Script5.setAttribute("id", "Script5");
            document.body.appendChild(Script5);

            //MAGNIFIC POP
            const script6Check = document.getElementById("Script6");
            if(script6Check){
                document.body.removeChild(script6Check);
            }
            let Script6 = document.createElement("script");
            Script6.setAttribute("src", "https://firebasestorage.googleapis.com/v0/b/omcn-62d76.appspot.com/o/DASH%20JS%2Fjquery.magnific-popup.min.js?alt=media&token=42959ee8-2fde-4c80-96cd-25be9f7261a4");
            Script6.setAttribute("id", "Script6");
            document.body.appendChild(Script6);

            //CHARTS
            const script7Check = document.getElementById("Script7");
            if(script7Check){
                document.body.removeChild(script7Check);
            }
            let Script7 = document.createElement("script");
            Script7.setAttribute("src", "https://firebasestorage.googleapis.com/v0/b/omcn-62d76.appspot.com/o/DASH%20JS%2Fcharts.js?alt=media&token=95932c08-ba22-4819-b9d4-7387d0b3e8c5");
            Script7.setAttribute("id", "Script7");
            document.body.appendChild(Script7);

            //APP
            const script8Check = document.getElementById("Script8");
            if(script8Check){
                document.body.removeChild(script8Check);
            }
            let Script8 = document.createElement("script");
            Script8.setAttribute("src", "https://firebasestorage.googleapis.com/v0/b/omcn-62d76.appspot.com/o/DASH%20JS%2Fapp.js?alt=media&token=184882ad-d8fa-4ecb-8538-f3bd841d292b");
            Script8.setAttribute("id", "Script8");
            document.body.appendChild(Script8);


        },

        data(){
          return{
            pin: "",
            sendCount: 0,
            loadingText: "",
            errorText: "",
            successText: "",

          }
        },

        methods:{

          closeS(){
            this.$refs.successModal.classList.remove("bg-active");
          },

          closeE(){
            this.$refs.errorModal.classList.remove("bg-active");
          },  

          async verify(){
            //start loading
            this.loadingText= "Verifying pin...";
            this.$refs.loadingModal.classList.add("bg-active");

            let pin = this.pin

            //check expiry
            if((new Date().getTime() / (1000 * 60)) > sessionStorage.getItem('pinExpiry')){
                this.$refs.loadingModal.classList.remove("bg-active");
                this.$refs.errorModal.classList.add("bg-active");
                this.errorText = "The pin has expired. Click resend to generate a new code";  
                return;
            }

            //validate pin

            if(pin == sessionStorage.getItem('verifyPin')){
              this.$refs.loadingModal.classList.remove("bg-active");
              this.$router.push('reset-password');
              return;

            }

            this.$refs.loadingModal.classList.remove("bg-active");
            this.$refs.errorModal.classList.add("bg-active");
            this.errorText = "Incorrect pin";
          },

          resend(){

            this.sendCount++;

            if(this.sendCount > 2){
              this.$refs.loadingModal.classList.remove("bg-active");
              this.$refs.errorModal.classList.add("bg-active");
              this.errorText = "You have exceeded your resend limit";
              return;
            }

            this.loadingText= "Resending pin...";
            this.$refs.loadingModal.classList.add("bg-active");

            let verifyPin = String(Math.floor(Math.random()*9000) + 1000);
            let name = sessionStorage.getItem("name");

            //get pin expiry
            let pinExpiry = (new Date().getTime() / (1000 * 60)) + 30;

            Email.send({
                    SecureToken: secureToken,
                    To : sessionStorage.getItem("email"),
                    From : "support@omcdex.io",
                    Subject : "Password Reset",
                    Body : `<!DOCTYPE html>
<html lang="en">
<head>

    <meta charset="UTF-8">
    <meta http-equiv="X-UA-Compatible" content="IE=edge">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <link rel="preconnect" href="https://fonts.googleapis.com">
    <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
    <link href="https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap" rel="stylesheet">
    
</head>
<body style="margin: 0; padding: 0; font-family: sans-serif; background-color: #ffffff; overflow-x: hidden; font-family: 'Nunito', sans-serif;">
    <section style="width: 100vw; margin: 0; padding: 0; background-color: #14112e;">
        <div style="text-align:center; padding-bottom: 20px; padding-top: 20px; width: 100%; ">
            <img src="https://firebasestorage.googleapis.com/v0/b/omcn-62d76.appspot.com/o/images%2Flogo.png?alt=media&token=bf7f4424-2c79-4d80-b73f-f49a9fe78bc4" alt="" width="130px">
        </div>

        <hr>

        <div style="padding-left: 10px; padding-right: 10px; padding-top: 20px; padding-bottom: 20px; font-size: 14px; background-color: #14112e; color: #ffffff;">

            <div style=" padding-top: 10px; padding-bottom: 20px; font-size: 16px; color: #ffffff;">
                Hello ${name}, 
            </div>

            <div style=" font-size: 12px; margin-bottom: 15px; color: #ffffff;">
                Use the pin below to reset your password on <a style="color: #da4bfb;" href="https://www.omcdex.io">OMCDEX</a>
            </div>  

            <div style=" font-size: 30px; margin-bottom: 15px; color: #da4bfb; font-weight: 800; ">
                ${verifyPin} 
            </div>

           
        </div>

        <hr>

        <div style=" padding-top: 10px; padding-left: 10px; padding-bottom: 20px; font-size: 12px; color: #ffffff;">
            Stay connected @ <span><a href="mailto:support@omcdex.io" style="color: #da4bfb;">support@omcdex.io</a></span>
        </div>

        <div style=" padding-top: 10px; padding-left: 10px; padding-bottom: 20px; font-size: 12px; color: #ffffff;">
            Risk warning: Cryptocurrency trading is subject to high market risk. OMCDEX will make the best efforts to choose high-quality coins, but will not be responsible for your trading losses. Please trade with caution.
        </div>

        <div style=" padding-top: 10px; padding-left: 10px; padding-bottom: 20px; font-size: 12px; color: #ffffff;">
            Kindly note: Please be aware of phishing sites and always make sure you are visiting the official omcdex.io website when entering sensitive data.
        </div>

        <div style="padding-left: 10px; padding-right: 10px; padding-top: 20px; padding-bottom: 70px; font-size: 12px; color: #ffffff;">
            ©2024 <span><a href="https://www.omcdex.io" target="_blank" rel="noopener noreferrer" style="color: #da4bfd;">omcdex.io</a></span> All Rights Reserved
        </div>
        
    </section>
</body>
</html>`
                }).
                then((message) => {
                    console.log(message);
                    sessionStorage.setItem("verifyPin", verifyPin);
                    sessionStorage.setItem("pinExpiry", pinExpiry);
                    this.$refs.loadingModal.classList.remove("bg-active");
                    this.$refs.successModal.classList.add("bg-active");
                    this.successText = "A new pin has been sent to your email. Remember to check your SPAM/JUNK folder if you do not find it in your primary inbox";


                }, (error) => {
                    this.$refs.loadingModal.classList.remove("bg-active");
                    this.$refs.errorModal.classList.add("bg-active");
                    this.errorText = "An error occured while sending the pin";
                    console.log(error);
                    return;
                });

          }

        }

    }
</script>